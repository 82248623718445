import { useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from '@nintendo-of-america/next';
import { useCustomer } from '@nintendo-of-america/react-hooks';
import { getGraph, useQuery } from '@nintendo-of-america/graph-api';
import { queries } from '@local/lib/graph';
import { localizer, metaTitle } from '@local/i18n';
import { ProductDetail } from '@local/components/pages/Products';
import {
  setFastlyHeaders,
  filterProductsByRating,
  getLocaleAlternates,
  StructuredData,
} from '@local/lib/helpers';
import getMerchBanner from '@local/lib/api/getMerchBanner';
import { promoRichTextCtaParser } from '@nintendo-of-america/contentful-api/parsers';
import { StoreWrap } from '@local/components/pages/Store';
import getPMP from '@local/lib/api/getPMP';
import * as PMPTemplates from '@local/components/pages/PMP/templates';
import { getLocaleUrlParam, cloudinaryUrl } from '@shared/util';
import {
  COUNTRY_IDS,
  US_CA_LOCALES,
  LOCALE_US_PARAM,
  FF_NAMES,
} from '@shared/util/constants';
import { DownloadDemo } from '@local/components';
import { getFeatureFlag } from '@local/lib/api';

const FIFTEEN_MINUTES = 900;
const MAX_TIMEOUT_DELAY = 2 ** 31;

export async function getServerSideProps(context) {
  const { res, locale, query } = context;
  const graph = getGraph(context);
  const { data } = await graph.query(queries.ProductByUrlKeyForPDP, {
    urlKey: query.slug,
    locale,
  });

  if (!data?.product) {
    setFastlyHeaders(res, { surrogateKeys: [`product:${query.slug}`] });
    return { notFound: true };
  }

  const {
    data: { features },
  } = await graph.query(queries.Features, {
    ids: ['wdev-1692-meta-description-update'],
    project: 'ncom',
  });

  const removeGoldPoints = await getFeatureFlag(
    FF_NAMES.DISABLE_GP_PDP,
    context
  );

  const returnPartialData = await getFeatureFlag('m-468', context);

  const { product } = data;

  let campaigns = [];
  if (product?.inviteRequired) {
    const result = await graph.query(queries.CampaignsByFilter);
    campaigns = result?.data?.campaigns || [];
  }

  const isGame = product.topLevelCategory?.code === 'GAMES';
  let productTitle = product.metaTitle || product.name;
  if (isGame && product.platform?.label) {
    productTitle = localizer.text('{0} for {1}', {
      args: [productTitle, product.platform.label],
      locale,
    });
  }
  let metaDescription;
  const useUpdatedMetaDescription = features?.length;
  if (useUpdatedMetaDescription) {
    metaDescription = localizer.text(
      'Buy {0} and shop other great Nintendo products online at the official My Nintendo Store',
      {
        args: [product.name],
        locale,
      }
    );
    const [, country] = locale.split('-');
    const localeParam = getLocaleUrlParam(locale);
    metaDescription += `${
      localeParam === LOCALE_US_PARAM
        ? ''
        : ' ' + COUNTRY_IDS[country.toUpperCase()]
    }.`;
  } else {
    metaDescription =
      product.metaDescription ||
      product.richTextDescription?.text ||
      product.description;
  }

  if (
    isGame &&
    !product.crossSellProducts &&
    US_CA_LOCALES.has(locale) &&
    !product.dlcType
  ) {
    // Query result is stored in apollo cache within page props
    await graph.query(queries.StoreProducts, {
      locale,
      where: { criteria: 'TOP_SELLING' },
      pageSize: 16,
    });
  }

  const localeAlternates = getLocaleAlternates(product, locale);

  const props = {
    meta: {
      title: metaTitle(productTitle, locale),
      description: metaDescription,
    },
    analytics: {
      pageType: `${isGame ? 'Game' : 'Product'} Detail Page`,
      pageName: product.name,
      pageCategory: 'Store:Products',
      product: {
        name: product.name,
        sku: product.sku,
        nsuid: product.nsuid || null,
      },
    },
    localeAlternates,
    removeGoldPoints,
    returnPartialData,
    campaign:
      campaigns.find((campaign) => {
        return campaign.meta?.sku === product?.sku;
      }) || null,
  };

  const pmp = await getPMP({ sku: product.sku, locale, graph });
  if (pmp?.content) {
    props.marketingPage = pmp.content;
    props.analytics.pageType = 'Marketing Detail Page';
    if (pmp.seo?.title || pmp.title) {
      props.meta.title = pmp.seo?.title || pmp.title;
    }
    if (pmp.seo?.description) props.meta.description = pmp.seo.description;
  }

  const merchBanners = await getMerchBanner({
    isOptional: true,
    getEntries: {
      'fields.products[match]': `${product.sku}`,
      locale,
    },
  });

  if (merchBanners) {
    const [promo] =
      merchBanners?.flatMap(({ fields }) => fields.promos || []) ?? [];
    if (promo) props.merchBanner = promoRichTextCtaParser(promo);
  }

  if (product.productImage?.publicId) {
    const shareableImage = cloudinaryUrl({
      publicId: product.productImage.publicId,
      variant: 'OG_IMAGE',
    });

    props.openGraph = { image: shareableImage };
    props.linkedData = [
      {
        '@type': isGame ? ['VideoGame', 'Product'] : ['Product'],
        ...StructuredData.product(
          { ...product, metaDescription: metaDescription },
          locale
        ),
        ...StructuredData.videoGame(product),
      },
      StructuredData.assets(
        [product.productImage, ...(product.productGallery ?? [])] || [
          product.productImage,
        ],
        product.name,
        product.createdAt
      ),
      StructuredData.breadcrumbs([
        { name: 'Store', url: `https://nintendo.com/${locale}/store/` },
        {
          name: product.topLevelCategory?.label,
          url: `https://nintendo.com/${locale}/store/${product.topLevelCategory?.label}/`,
        },
        { name: product.name },
      ]),
    ];
  }

  const relatedSkus = product.relatedProducts?.map(({ sku }) => sku) || [];
  const variationSkus =
    product.variations?.map(({ product }) => product?.sku) || [];
  const surrogateKeys = [
    product.sku,
    product.configurableProduct?.sku,
    ...relatedSkus.slice(0, 4),
    ...variationSkus,
  ].filter(Boolean);

  setFastlyHeaders(res, { surrogateKeys });
  if (product.eshopDetails?.discountPriceEnd) {
    const secondsUntilSaleEnds = Math.floor(
      (Date.parse(product.eshopDetails.discountPriceEnd) - Date.now()) / 1000
    );

    const maxAge = Math.min(secondsUntilSaleEnds, FIFTEEN_MINUTES);

    if (maxAge > 0) {
      res.setHeader('Cache-Control', `max-age=${Math.min(maxAge, 60)}`);
      res.setHeader(
        'Surrogate-Control',
        `max-age=${maxAge}, stale-if-error=86400`
      );
    }
  }

  return graph.getServerSideProps(props);
}

export default function ProductPageTemplate({
  children,
  campaign,
  merchBanner,
  marketingPage,
  showReadMore = true,
  removeGoldPoints,
  returnPartialData,
}) {
  const { locale, query } = useRouter();
  const customer = useCustomer();
  const customersCountry = customer.data?.country.toLowerCase();
  const wrongLocale = !locale.endsWith(customersCountry);
  const [personalized, setPersonalized] = useState({});

  const productQuery = useQuery(
    queries.ProductByUrlKeyForPDP,
    {
      urlKey: query.slug,
      locale,
      personalized: Boolean(personalized[query.slug]),
    },
    {
      fetchPolicy: 'cache-only',
      returnPartialData,
    }
  );

  const { sku, nsuid, inviteRequired } = productQuery.data.product;

  const invitesQuery = useQuery(queries.InvitesByUser, null, {
    authenticated: true,
    skip: !customer.data || !inviteRequired,
    // Setting cache policy to no-cache here since data could be changing frequently.
    fetchPolicy: 'no-cache',
  });

  const pricingQuery = useQuery(
    queries.ProductBySkuForDigitalPDP,
    { sku, locale },
    {
      authenticated: true,
      skip: !nsuid || !customer.data || wrongLocale,
    }
  );
  if (pricingQuery.data?.product && !personalized[query.slug]) {
    setPersonalized((prev) => ({ ...prev, [query.slug]: true }));
  } else if (personalized[query.slug] && !customer.data) {
    setPersonalized((prev) => ({ ...prev, [query.slug]: false }));
  }

  const loadingPricing = customer.loading || pricingQuery.loading;
  const loadingInvites = invitesQuery.loading;

  const product = useMemo(
    () => ({
      ...productQuery.data.product,
      loadingPricing,
      removeGoldPoints,
      waitlist: {
        campaign,
        invite: loadingInvites
          ? null
          : invitesQuery.data?.invites.find(
              (invite) => invite.campaignId === campaign?.id
            ) || null,
        loadingInvites: loadingInvites,
        refreshInvites: invitesQuery.refetch,
      },
    }),
    [
      productQuery.data.product,
      invitesQuery,
      campaign,
      loadingInvites,
      loadingPricing,
      removeGoldPoints,
    ]
  );

  const timeout = useRef();
  useEffect(() => {
    if (timeout.current) clearTimeout(timeout.current);
    if (product.eshopDetails?.discountPriceEnd) {
      const discountEnds = Math.ceil(
        Date.parse(product.eshopDetails.discountPriceEnd) - Date.now()
      );
      if (discountEnds > 0 && discountEnds < MAX_TIMEOUT_DELAY) {
        timeout.current = setTimeout(
          () => (customer.data ? pricingQuery : productQuery).refetch(),
          discountEnds
        );
      }
    }
    return () => timeout.current && clearTimeout(timeout.current);
  }, [
    product.eshopDetails?.discountPriceEnd,
    customer.data,
    productQuery,
    pricingQuery,
  ]);

  const bestSellersQuery = useQuery(
    queries.StoreProducts,
    { locale, where: { criteria: 'TOP_SELLING' }, pageSize: 16 },
    // TOP_SELLING is queried for in gSSP, so the cache should already be populated.
    { fetchPolicy: 'cache-only' }
  );

  const bestSellers = useMemo(() => {
    if (!bestSellersQuery.data?.products?.length) return [];
    return filterProductsByRating({
      products: bestSellersQuery.data.products,
      rating: product.contentRating,
    });
  }, [bestSellersQuery.data, product.contentRating]);

  const showMarketingPage =
    (marketingPage && marketingPage?.modularPageReference?.templateName) ||
    marketingPage?.templateName;

  const MarketingPageTemplate = showMarketingPage
    ? PMPTemplates[
        marketingPage.templateName ??
          marketingPage.modularPageReference?.templateName
      ]
    : null;
  return (
    <StoreWrap disableScrollRestoration={true}>
      <ProductDetail
        product={product}
        merchBanner={merchBanner}
        showReadMore={showMarketingPage ? false : showReadMore}
        bestSellers={bestSellers}
      >
        {showMarketingPage ? (
          <MarketingPageTemplate
            marketingPage={marketingPage.modularPageReference || marketingPage}
            productProps={product}
            /** Demo button portal for use on nintendo.com-marketing */
            DemoButton={() => (
              <DownloadDemo variant="button" product={product} />
            )}
            locale={locale}
          />
        ) : (
          children
        )}
      </ProductDetail>
    </StoreWrap>
  );
}
